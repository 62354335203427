<template>
	<div class="tools">
		<div class="filters">
			<Interval ref="interval" @change="this.currentPage=1, this.total=0, this.interval=$event, this.loadData($event)" />
		</div>
	</div>

	<div class="leads">
 	<o-table ref="table" :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true :mobile-cards=false :paginated=true :per-page="perpage" :total="total" :pagination-simple=true v-model:current-page="currentPage" :backendPagination=true @page-change="pageChange()" class="leads">
		<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">

		{{ row[column.field] }}

		</o-table-column>
	</o-table>
	</div>
</template>

<script>
	import Interval from './Interval.vue'
	export default {
		name: 'LeadsAll',
		components: {
			Interval
		},
		data() {
			return {
				columns: [
					{ field:'date', label:'Дата', },
					{ field:'offer_name', label:'Оффер', },
					{ field:'country', label:'Страна', },
					{ field:'aff', label:'ПП', },
					{ field:'offer', label:'id оффера', },
					{ field:'status_id', label:'Статус', },
					{ field:'payout', label:'Выплата', },
					{ field:'name', label:'Имя', },
					{ field:'phone', label:'Телефон', },
					{ field:'email', label:'e-mail', },
					{ field:'subid', label:'subid', },
					{ field:'branch_id', label:'КТ', },
					{ field:'kt_campaign', label:'Кампания', },
					{ field:'kt_offer', label:'Лендинг', },
					{ field:'ip', label:'ip', },
					{ field:'ua', label:'UserAgent', },
				],
				data: [],
				params: {'api': 'https://postback.uniaffcrm.com/api.php?subid=all', },
				interval: {},
				perpage: 100,
				currentPage: 1,
				total: 0,
			}
		},
		methods: {
			pageChange(){
				this.loadData(this.interval)
			},
			loadData(e) {
				if(e && e.interval) this.params.interval = e.interval
				if(e && e.interval == 'custom_date_range') {
					this.params.from = e.from
					this.params.to = e.to
				}
				this.params.offset = (this.$refs.table.newCurrentPage-1)*this.perpage
				if(this.total) {
					this.$root.loadData(this.params, this, (dt) => { this.data = dt })
				} else {
					this.$root.loadData({...this.params, ...{action:'Total'}}, this, (dt) => {
						this.total = dt.total
						this.$root.loadData(this.params, this, (dt) => { this.data = dt })
					})
				}
			},

		},
		mounted() {
			this.params.user = this.$root.user.name
			this.params.limit = this.perpage
			this.loadData(null)
		},
	}
</script>

<style>
/*.leads{ overflow-x:scroll; }*/
th,td{ font-size:0.8em!important; max-width:12em; overflow:hidden; white-space:nowrap!important; }

</style>
